
function getKey(params) {
  return JSON.stringify([
    params.paginationModel,
    params.filterModel,
    params.sortModel,
    params.groupKeys,
    params.groupFields,
  ]);
}

export class GridDataSourceCacheDefault {
  cache;

  ttl;

  constructor({ ttl = 300000 }) {
    this.cache = {};
    this.ttl = ttl;
  }

  set(key, value) {
    const keyString = getKey(key);
    const expiry = Date.now() + this.ttl;
    this.cache[keyString] = { value, expiry };
  }

  get(key){
    const keyString = getKey(key);
    const entry = this.cache[keyString];
    if (!entry) {
      return undefined;
    }
    if (Date.now() > entry.expiry) {
      delete this.cache[keyString];
      return undefined;
    }
    return entry.value;
  }

  clear() {
    this.cache = {};
  }
}
