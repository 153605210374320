export default function post(url, data = undefined , config = {}) {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-transform',
                // 'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36',
                ...config.headers || {}
            };

            let response = await fetch(url, {
                method: "post",
                headers: new Headers(headers),
                body: JSON.stringify(data),
            });

            if (response.status == 200) {
                let r = undefined;
                if (config && config.responseType && config.responseType == 'arraybuffer') {
                    r = await response.arrayBuffer();
                } else {

                    if (response.headers && response.headers.get('content-type').includes("text/html")) {
                        r = await response.text();
                    } else if (response.headers.get('content-type').includes("application/json")) {
                        r = await response.json();
                    } else {
                        r = await response.json();
                    }
                }
                response = r;
            }
            
            if (response.status !== 200) {
                reject({ status: response.status, message: response.message || response.statusText });
            } else {
                resolve(response);
            }
        }catch(err){
            reject(err);
        }
    });
}







/*//import 'isomorphic-fetch';
import STATUS from '../status';

export default function post(url, data = undefined, config = {}) {

    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...config.headers || {}
    };

    return fetch(url, {

        headers: new Headers(headers),
        method: 'POST',
        body: JSON.stringify(data),

    }).then((res) => {
        if (res.status === STATUS.NO_CONTENT || (res?.headers?.get('Content-Length') === "0")) {
            return true;
        } else {
            return res.json();
        }

    }).then((res) => {

        if (res.errorCode !== undefined || res.error !== undefined) {
            res.errorCode = res.errorCode ? res.errorCode : res.error;
            throw res;
        } else {
            return res;
        }

    }).catch((err) => {

        return Promise.reject(STATUS.mdw(err));

    });
}
*/