import { injectIntl } from 'react-intl';
import { useEffect, useState, useRef } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

function AddCardDialog(props) {
    const intl = props.intl;

    const [langue, setLangue] = useState(undefined);
    const [isFoil, setIsFoil] = useState(props?.set?.foil_only || false);
    const [note, setNote] = useState("");

    const languages = [...props?.languages];

    /*
    if (props?.set && (props?.set.isForeignOnly == undefined || props?.set.isForeignOnly == false) && (props?.card.isAlternative == undefined || props?.card.isAlternative == false)) {
        languages.find((el) => el.language == "English").disabled = false;
    }*/

    props?.card?.lang?.forEach((el) => {
        languages.find((e) => e.label == el).disabled = false;
    });

    return <Dialog onClose={() => { props.onClose && props.onClose(); }} open={true} >
        <DialogTitle>{props.title || ""}</DialogTitle>
        <DialogContent dividers>
            <List>
                <ListItem>
                    {languages?.map((l, i) => {
                        return <span style={{ paddingLeft: i === 0 ? '0' : '5px' }}>
                            <BootstrapTooltip title={l.language || ""} arrow placement="top">
                                <Button sx={{ fontSize: 12, minWidth: "16px", lineHeight: "initial" }} variant={l.label == langue ? "contained" : "outlined"} size="small" disabled={l.disabled} onClick={() => { setLangue(l.label); }}>{l.display}</Button>
                            </BootstrapTooltip>
                        </span>
                    })}
                </ListItem>
                <ListItem>
                    <ListItemIcon><Checkbox name="isFoil" checked={isFoil} onChange={(event) => {
                        setIsFoil(props?.set.isFoilOnly ? true : event.target.checked)
                    }} /></ListItemIcon>
                    <ListItemText primary="isFoil" />
                </ListItem>
                <ListItem>
                    <TextField type="text" name="note" variant="outlined" sx={{ width: "100%", textAlign: "center" }} onChange={(event) => {
                        setNote(event.target.value)
                    }} />
                </ListItem>
            </List>
        </DialogContent>
        <DialogActions dividers sx={{ display: "block" }}>
            <Grid container spacing={2} >
                <Grid size={6} sx={{ textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        color="success"
                        sx={{ width: '100%' }}
                        onClick={() => {
                            props.onValid && props.onValid(props?.card, langue, isFoil, note);
                        }}
                    >{intl.formatMessage({ id: 'Button.validate' })}</Button>
                </Grid>
                <Grid size={6} sx={{ textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        color="error"
                        sx={{ width: '100%' }}
                        onClick={() => {
                            props.onClose && props.onClose();
                        }}
                    >{intl.formatMessage({ id: 'Button.cancel' })}</Button>
                </Grid>
            </Grid>
        </DialogActions>
    </Dialog >
}

export default injectIntl(AddCardDialog);