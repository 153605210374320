import user from "./user";
import blocks from './blocks';
import sets from './sets';
import set from './set';
import expansion from "./expansion";
import collection from "./collection";
import cards from './cards';
import search from './search';
import install from './install';

export default {
    install,
    user,
    blocks,
    sets,
    set,
    expansion,
    collection,
    cards,
    search
}