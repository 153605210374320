import { useGridAriaAttributes as useGridAriaAttributesCommunity } from '../../../x-data-grid/internals';
import { useGridRootProps } from './useGridRootProps';

export const useGridAriaAttributes = () => {
  const ariaAttributesCommunity = useGridAriaAttributesCommunity();
  const rootProps = useGridRootProps();

  const ariaAttributesPro = rootProps.treeData ? { role: 'treegrid' } : {};

  return {
    ...ariaAttributesCommunity,
    ...ariaAttributesPro,
  };
};
