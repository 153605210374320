import { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { withNavigation } from '@remyar/react-navigation';
import { withStoreProvider } from '@remyar/react-store';
import { withSnackBar } from '@remyar/react-snackbar';

import actions from '../../actions';
import Router from '../../routes';

function HomePage(props) {
    const intl = props.intl;
    async function _fetchData() {
        try {
            let result = await props.dispatch(actions.user.getSession());
            if (result.message) {
                props.snackbar.info(intl.formatMessage({ id: result.message }));
            }
            else {
                props.navigation.push(Router.urlBlocks());
            }
        } catch (err) {
            props.navigation.push(Router.urlLogin());
        }
    }
    useEffect(() => {
        _fetchData();
    }, [])
    return;
}

export default withStoreProvider(withNavigation(withSnackBar(injectIntl(HomePage))));