import { useEffect, useState, forwardRef } from 'react';
import { injectIntl } from 'react-intl';
import { withNavigation } from '@remyar/react-navigation';
import { withStoreProvider } from '@remyar/react-store';
import { withSnackBar } from '@remyar/react-snackbar';

import Loader from '../../components/loader';
import BlockCard from '../../components/blockCard';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import SetCard from '../../components/setCard';

import actions from '../../actions';
import Router from '../../routes';
import utils from '../../utils';

import { GroupedVirtuoso } from 'react-virtuoso';


function BlocksPage(props) {

    const intl = props.intl;

    const [displayLoader, setDisplayLoader] = useState(true);
    const [blocks, setBlocks] = useState({ count: [], group: [] });

    async function fetchData() {
        try {
            setDisplayLoader(true);

            let result = props.globalState.sets;
            result = (await props.dispatch(actions.sets.all()))?.sets || [];

            let _blocks = {};
            for (let set of result) {

                if (_blocks[set.block_code] == undefined) {
                    _blocks[set.block_code] = [];
                }

                _blocks[set.block_code].push(set)
            }

            let _groups = { count: [], group: [] };
            Object.keys(_blocks).map((key) => {
                _groups.count.push(Math.ceil(_blocks[key].length / 4));
                _groups.group.push(_blocks[key]);
            });

            setBlocks(_groups);

        } catch (err) {
            props.snackbar.error(err.message);
        } finally {
            setDisplayLoader(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return <Box>
        <Loader display={displayLoader} />
        <Box>
            <GroupedVirtuoso
                useWindowScroll
                groupCounts={blocks.count}
                groupContent={(index, context) => {
                    let item = blocks.group[index];

                    return (<Box sx={{ marginLeft: "-5px", width: "100%", height: "50px", backgroundColor: "white", border: "5px solid white" }}>
                        <Typography component="div" variant="h5" noWrap>
                            <b>{utils.BlockLocalise.getTranslationName(item[0], props.globalState.settings) || "unknow"}</b>
                        </Typography>
                        <Divider orientation="horizontal" style={{ maxHeight: "5px", width: "100%" }} />
                    </Box>);
                }}
                itemContent={(index, groupIndex) => {

                    for (let i = 0; i < groupIndex; i++) {
                        index -= blocks.count[i];
                    }

                    index *= 4;

                    let items = blocks.group[groupIndex].slice(index, index + 4);
                    return (
                        < Grid container spacing={2} sx={{ paddingTop: '15px' }}>
                            {items?.map((item) => (<Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                                <SetCard
                                    expansion={item}
                                    collection={props.globalState.collection.filter((e) => e.set_code == item.code)}
                                    onClick={() => {
                                        props.navigation.push(Router.urlSet(item.code))
                                    }}
                                />
                            </Grid>))}
                        </Grid>

                    )
                }}
            />
        </Box>

    </Box>

    /*
    
        return <Box>
            <Loader display={displayLoader} />
            <Box>
                {(blocks.length > 0) && <VirtuosoGrid
                    useWindowScroll
                    totalCount={blocks.length}
                    components={gridComponents}
                    itemContent={(index) => {
                        let item = blocks[index];
                        if (item.type == 'divider') {
                            return <Box >
                                <Typography component="div" variant="h5" noWrap>
                                    <b>{item.title || "unknow"}</b>
                                </Typography>
                                <Divider orientation="horizontal" style={{ maxHeight: "5px", width: "100%" }} />
                            </Box >
                        } else if (item.type == "card") {
                            return <SetCard
                                expansion={item.set}
                                collection={props.globalState.collection.filter((e) => e.set_code == item.set.code)}
                                onClick={() => {
                                    props.navigation.push(Router.urlSet(item.set.code))
                                }}
                            />
                        }
                    }}
                />}
            </Box>
    
        </Box>
    
    
    
    
        return <Box>
    
            <Loader display={displayLoader} />
            <Box>
                {Object.keys(blocks).map((key) => {
                    let block = blocks[key];
                    return <>
    
                        <Divider />
                        < Grid container spacing={2} sx={{ paddingTop: '15px' }}>
                            {block.map((set) => {
                                return <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                                    <SetCard
                                        expansion={set}
                                        collection={props.globalState.collection.filter((e) => e.set_code == set.code)}
                                        onClick={() => {
                                            props.navigation.push(Router.urlSet(set.code))
                                        }} />
                                </Grid>
                            })}
                        </Grid>
                    </>
                })}
    
    
                {/*blocks?.map((set, i) => {
                        return <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                            <SetCard
                                expansion={sets[index]}
                                collection={props.globalState.collection.filter((e) => e.set_code == sets[index].code)}
                                onClick={() => {
                                    props.navigation.push(Router.urlSet(sets[index].code))
                                }} />
                        </Grid>
                    })}
    
            </Box>
        </Box>;*/
}


export default withStoreProvider(withNavigation(withSnackBar(injectIntl(BlocksPage))));