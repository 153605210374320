import utils from '../../utils';

export default {
    byWord: utils.actions(async (word, { extra, getState }) => {
        try {
            let sets = getState().sets;
            const api = extra.api;
            word = word.trim();
            let searchCard = [];
            for (let set of sets) {
                let result = (await api.method.get("api/v1/public/set/" + set.code))?.cards || [];
                let cards = result?.map((e) => {
                    let find = false;

                    for ( let langue in e.name){
                        if ( e.name[langue].toUpperCase().includes(word.toUpperCase()) === true){
                            find = true;
                        }
                    }

                    if (find == true) {
                        return {...e , set_code : set.code};
                    }
                }).filter((e) => e != undefined);

                if (cards?.length > 0) {
                    searchCard.push(cards);
                }
            }
            searchCard = searchCard?.flatMap((x) => x);
            return { search: searchCard }
        } catch (err) {
            throw { message: err.message };
        }
    })
}