export default {
    getTranslationName: (expansion, settings = { translateInLocale: false }) => {
        let text = expansion?.name || '';
        if (settings.translateInLocale === true) {
            let textTranslate = ((expansion?.translations && expansion?.translations[settings.locale]) || '');
            if (textTranslate) {
                text = textTranslate;
            }
        }
        return text;
    }
}