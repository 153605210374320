import utils from '../../utils';

export default {
    start: utils.actions(async ({ extra, getState }) => {
        try {
            let api = extra.api;

            let sets = (await api.method.get("api/v1/public/sets")).sets;
            for (let set of sets) {
                await api.method.get("api/v1/public/set/" + set.code);
            }

        } catch (err) {
            throw err;
        }
    }),
}