import utils from '../../utils';

export default {
    getByMultiverseId: utils.actions(async (multiverseId, { extra }) => {
        try {
            let result = await extra.api.method.get("/api/v1/public/cards", { query: { multiverseId: multiverseId }});
            return { cards : result.cards }
        } catch (err) {
            throw { message: err.message };
        }
    })
}