import { Details } from '@mui/icons-material';
import utils from '../../utils';

export default {
    all: utils.actions(async ({ extra, getState }) => {
        try {
            let api = extra.api;

            let result = (await api.method.get("api/v1/public/sets")).sets;

            let _blocks = {};
            let blocks = [];

            const blocksType = [
                { name: "archenemy", defaultBlock: "Archenemy", defaultBlockCode: "ARC" },
                { name: "box", defaultBlock: "Box", defaultBlockCode: "ATH" },
                { name: "commander" },
                { name: "core" },
                { name: "draft_innovation", defaultBlock: "DraftInnovation", defaultBlockCode: "CNS" },
                { name: "duel_deck", defaultBlock: "DuelDeck", defaultBlockCode: "DVD" },
                { name: "expansion" },
                { name: "from_the_vault" },
                { name: "funny", defaultBlock: "Funny", defaultBlockCode: "UGL" },
                { name: "masterpiece" },
                { name: "memorabilia" },
                { name: "planechase" },
                { name: "premium_deck" },
                { name: "spellbook" },
                { name: "starter", defaultBlock: "Starter", defaultBlockCode: "POR" },
                { name: "token" },
                { name: "treasure_chest" },
                { name: "vanguard" },
            ];

            for (const bType of blocksType) {
                let sets = result && result.filter((s) => s.type == bType.name);
                for (let set of sets) {
                    set.block = bType.defaultBlock ? bType.defaultBlock : set.block;
                    set.block_code = bType.defaultBlockCode ? bType.defaultBlockCode : set.block_code;
                    if (set.block != undefined) {
                        if (_blocks[set.block_code] == undefined) {
                            _blocks[set.block_code] = [];
                        }
                        _blocks[set.block_code].push(set);
                    }
                }
            }

            /*
            let keys = Object.keys(_blocks);
            for (let key of keys) {
                blocks.push({ ..._blocks[key].find((el) => el.code == key), expansions: [], totalCardCount: 0, icon_svg_data: "" });
            }

            for (const block of blocks) {
                for (const b of _blocks[block.block_code]) {
                    if (b.isNonFoilOnly == undefined) {
                        b.isNonFoilOnly = false;
                    }
                    block.expansions.push(b);
                    block.totalCardCount += b.totalSetSize;
                }

                for (let expansion of block.expansions) {

/*                    try {
                        expansion.icon_svg_data = (await api.method.get("api/v1/public/svg/" + expansion.icon_svg_uuid)).svg;
                    } catch (err) {
                        expansion.icon_svg_data = '';
                    }
*/
  /*                  expansion.locale_name = utils.ExpansionLocalise.getTranslationName(expansion, getState().settings);

                }

                block.expansions.sort((a, b) => {
                    return new Date(a.releaseDate).getTime() - new Date(b.releaseDate).getTime();
                });

                block.expansions = block.expansions.reverse();
            }

            blocks.sort((a, b) => {
                return new Date(a.releaseDate).getTime() - new Date(b.releaseDate).getTime();
            });

/*
            for (let block of blocks) {
                try {
                    block.icon_svg_data = (await api.method.get("api/v1/public/svg/" + block.icon_svg_uuid)).svg;
                } catch (err) {
                    console.error(err);
                }
            }
            result.data = blocks.reverse();
*/
            return { blocks: blocks };
        } catch (err) {
            throw err;
        }
    })
}