'use client';
import * as React from 'react';
import { GridBody, GridFooterPlaceholder, GridHeader, GridRoot } from '../components';
import { useGridAriaAttributes } from '../hooks/utils/useGridAriaAttributes';
import { useGridRowAriaAttributes } from '../hooks/features/rows/useGridRowAriaAttributes';
import { GridContextProvider } from '../context/GridContextProvider';
import { useDataGridComponent } from './useDataGridComponent';
import { useDataGridProps } from './useDataGridProps';
import {
    propValidatorsDataGrid,
    validateProps,
} from '../internals/utils/propValidation';

const configuration = {
    hooks: {
        useGridAriaAttributes,
        useGridRowAriaAttributes,
    },
};
let propValidators;

if (process.env.NODE_ENV !== 'production') {
    propValidators = [
        ...propValidatorsDataGrid,
        // Only validate in MIT version
        (props) =>
            (props.columns &&
                props.columns.some((column) => column.resizable) &&
                [
                    `MUI X: \`column.resizable = true\` is not a valid prop.`,
                    'Column resizing is not available in the MIT version.',
                    '',
                    'You need to upgrade to DataGridPro or DataGridPremium component to unlock this feature.',
                ].join('\n')) ||
            undefined,
    ];
}

const DataGridRaw = React.forwardRef(function DataGrid(inProps, ref) {
    const props = useDataGridProps(inProps);
    const privateApiRef = useDataGridComponent(props.apiRef, props);

    if (process.env.NODE_ENV !== 'production') {
        validateProps(props, propValidators);
    }
    return (
        <GridContextProvider privateApiRef={privateApiRef} configuration={configuration} props={props}>
            <GridRoot
                className={props.className}
                style={props.style}
                sx={props.sx}
                ref={ref}
                {...props.forwardedProps}
            >
                <GridHeader />
                <GridBody />
                <GridFooterPlaceholder />
            </GridRoot>
        </GridContextProvider>
    );
});

export const DataGrid = React.memo(DataGridRaw);
