import { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { withNavigation } from '@remyar/react-navigation';
import { withStoreProvider } from '@remyar/react-store';
import { withSnackBar } from '@remyar/react-snackbar';

import actions from '../../actions';
import Router from '../../routes';
import utils from '../../utils';

function InstallPage(props) {
    const intl = props.intl;

    async function serviceWorkerIsActivated() {
        if (navigator.serviceWorker) {
            let registration = await navigator.serviceWorker.getRegistration();
            if (registration?.active?.state === "activated") {
               // props.dispatch(actions.install.start());
                return true;
            } else {
                return false;
            }
        } else {
            return true
        }
    }

    async function _fetchData(){
        try{
            let r = false;
            do {
                r = await serviceWorkerIsActivated();
                if (r == false) {
                    await utils.sleep(500);
                }
            } while (r == false);

            let result = await props.dispatch(actions.user.getSession());
            if (result.message) {
                props.snackbar.info(intl.formatMessage({ id: result.message }));
            } 
            await props.dispatch(actions.collection.get()); 
            props.navigation.push(Router.urlBlocks());
        }catch(err){
            props.navigation.push(Router.urlLogin());
        }
    }
    useEffect(()=>{
        _fetchData();
    },[]);

    return ;
}

export default withStoreProvider(withNavigation(withSnackBar(injectIntl(InstallPage))));