import * as React from 'react';
import { gridDimensionsSelector } from '../dimensions';
import { useGridApiEventHandler } from '../../utils/useGridApiEventHandler';

export const listViewStateInitializer = (state, props, apiRef) => ({
  ...state,
  listViewColumn: { ...props.unstable_listColumn, computedWidth: getListColumnWidth(apiRef) },
});

export function useGridListView(
  apiRef,
  props,
) {
  /*
   * EVENTS
   */
  const updateListColumnWidth = () => {
    apiRef.current.setState((state) => {
      if (!state.listViewColumn) {
        return state;
      }
      return {
        ...state,
        listViewColumn: {
          ...state.listViewColumn,
          computedWidth: getListColumnWidth(apiRef),
        },
      };
    });
  };

  const prevInnerWidth = React.useRef(null);
  const handleGridSizeChange = (
    viewportInnerSize,
  ) => {
    if (prevInnerWidth.current !== viewportInnerSize.width) {
      prevInnerWidth.current = viewportInnerSize.width;
      updateListColumnWidth();
    }
  };

  useGridApiEventHandler(apiRef, 'viewportInnerSizeChange', handleGridSizeChange);
  useGridApiEventHandler(apiRef, 'columnVisibilityModelChange', updateListColumnWidth);

  /*
   * EFFECTS
   */
  React.useEffect(() => {
    const listColumn = props.unstable_listColumn;
    if (listColumn) {
      apiRef.current.setState((state) => {
        return {
          ...state,
          listViewColumn: {
            ...listColumn,
            computedWidth: getListColumnWidth(apiRef),
          },
        };
      });
    }
  }, [apiRef, props.unstable_listColumn]);
}

function getListColumnWidth(apiRef) {
  return gridDimensionsSelector(apiRef.current.state).viewportInnerSize.width;
}
