
import Box from '@mui/material/Box';


function DisplayImage(props){
    let sx={ position : "absolute" , top : props.position.y , left :  props.position.x}
    return <Box sx={sx}>
        <img 
            src={props.card.image_normal} 
            width={props.width}
            height={props.height}
            />
    </Box>
}


export default DisplayImage;