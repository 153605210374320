import utils from '../../utils';

export default {
    get: utils.actions(async (uuid, { extra, getState }) => {
        try {
            let api = extra.api;

            let result = (await api.method.get("api/v1/public/expansion/" + uuid)).set;
            let symbology = (await api.method.get("api/v1/public/symbology")).symbology;
            for (let _d of symbology?.data) {
                try {
                    _d.svg_data = (await api.method.get("api/v1/public/svg/" + _d.svg_uuid)).svg;
                } catch (err) {
                    _d.svg_data = '';
                }
            }

            if (result.data.isNonFoilOnly == undefined) {
                result.data.isNonFoilOnly = false;
            }

            for (let card of result.data.cards) {
                card.locale_name = utils.CardLocalise.getTranslationName(card, getState().settings);

                card.colors = card?.colors ? card.colors : [];
                card.colors.map((color, idx) => {
                    card.colors[idx] = symbology?.data.find((el) => el.loose_variant == color);
                });

                card.formatted_color = card.colors[0] && card.colors[0].svg_uri || '';
                if (card.colors.length > 1) {
                    //-- multicolor
                    card.formatted_color = symbology?.data.find((el) => el.loose_variant == 'C').svg_uri || '';
                }

                card.formatted_color_svg = card.colors[0] && card.colors[0].svg_data || '';
            }
            return {
                expansion: result.data
            }
        } catch (err) {
            throw { message: err.message };
        }
    })
}