
import * as React from 'react';
import { useGridRootProps } from '../../hooks/utils/useGridRootProps';

export const GridColumnUnsortedIcon = React.memo(function GridColumnHeaderSortIcon(
  props,
) {
  const { sortingOrder, ...other } = props;
  const rootProps = useGridRootProps();
  const [nextSortDirection] = sortingOrder;

  const Icon =
    nextSortDirection === 'asc'
      ? rootProps.slots.columnSortedAscendingIcon
      : rootProps.slots.columnSortedDescendingIcon;

  return Icon ? <Icon {...other} /> : null;
});
