import { useEffect, useState, useRef, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { withNavigation } from '@remyar/react-navigation';
import { withStoreProvider } from '@remyar/react-store';
import { withSnackBar } from '@remyar/react-snackbar';
import { useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';

import Loader from '../../components/loader';
import DataGrid from '../../components/datagrid';
import AddCardDialog from '../../components/addCardDialog';
import DisplayImage from '../../components/displayImage';
import CardFilter from '../../components/cardFilter';

import actions from '../../actions';
import utils from '../../utils';
import { CollectionsTwoTone, FormatColorFill } from '@mui/icons-material';
import RemoveCarddialog from '../../components/removeCarddialog';
import { useGridColumnMenuSlots } from '../../components/datagrid/x-data-grid/hooks/features/columnMenu/useGridColumnMenuSlots';

import MtgSvg from '../../components/mtgSvg'

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

function ExpansionPage(props) {

    const { code } = useParams();
    const [set, setSet] = useState(props.globalState.sets.find((e) => e.code === code));
    const [displayLoader, setDisplayLoader] = useState(true);
    const [cards, setCards] = useState([]);
    const [collection, setCollection] = useState(props.globalState?.collection?.filter((e) => e.set_code == code) || []);
    const [addCard, setAddCard] = useState({ visible: false });
    const [removeCard, setRemoveCard] = useState({ visible: false });
    const [cardPrint, setCardPrint] = useState({ visible: false, position: undefined, card: undefined });
    const [filter, setFilter] = useState("");

    async function fetchData() {
        try {
            setDisplayLoader(true);
            let result = await props.dispatch(actions.set.get(code));
            if (result.set) {
                setSet(result.set);
                setCards(result.set.cards);
            }

        } catch (err) {
            props.snackbar.error(err.message);
        } finally {
            setDisplayLoader(false);
        }
    }

    async function onLanguageClick(card, language) {
        try {
            if (set.foil_only == true) {
                let result = await props.dispatch(actions.collection.addFoil(set, card, language.label, ""));
                setCollection(result.collection.filter((e) => e.set_code == code));
            } else {
                let result = await props.dispatch(actions.collection.addNormal(set, card, language.label, ""));
                setCollection(result.collection.filter((e) => e.set_code == code));
            }
        } catch (err) {
            props.snackbar.error(err.message);
        } finally {

        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    let totalPrice = 0;
    let totalPriceFoil = 0;

    let inCollectionCardCount = 0;
    let inCollectionCardFoilCount = 0;
    let totalCollectionCardCount = 0;
    let totalCollectionCardFoilCount = 0;

    let finalCollection = [];
    collection.map((c) => {
        if (c.isFoil) {
            inCollectionCardFoilCount++;
        } else {
            inCollectionCardCount++;
        }

        if (finalCollection.find((e) => e.card_id == c.card_id) == undefined) {
            if (c.isFoil) {
                totalCollectionCardFoilCount++;
                let _card = cards.find((_e) => _e.id == c.card_id);
                totalPrice += parseFloat(_card?.prices?.eur_foil?.toString());
            } else {
                totalCollectionCardCount++;
                let _card = cards.find((_e) => _e.id == c.card_id);
                totalPrice += parseFloat(_card?.prices?.eur?.toString());
            }
            finalCollection.push(c);
        }
    });

    let Language = [
        { label: "en", display: "en", language: "English", disabled: true },
        { label: "es", display: "es", language: "Spanish", disabled: true },
        { label: "fr", display: "fr", language: "French", disabled: true },
        { label: "de", display: "de", language: "German", disabled: true },
        { label: "it", display: "it", language: "Italian", disabled: true },
        { label: "pt", display: "pt", language: "Portuguese (Brazil)", disabled: true },
        { label: "ja", display: "ja", language: "Japanese", disabled: true },
        { label: "ko", display: "ko", language: "Korean", disabled: true },
        { label: "ru", display: "ru", language: "Russian", disabled: true },
        { label: "zhs", display: "汉语", language: "Chinese Simplified", disabled: true },
        { label: "zht", display: "漢語", language: "Chinese Traditional", disabled: true }
    ];

    const headers = [
        {
            id: 'number', label: props.intl.formatMessage({ id: "DataGridCardList.Number" }), width: 75, render: ((card) => {
                return <Box sx={{ width: "100%", height: "100%", textAlign: "center" }}>
                    {card.collector_number}
                </Box>
            })
        },
        {
            id: 'name', label: props.intl.formatMessage({ id: "DataGridCardList.Name" }), flex: 1, render: ((card) => {
                return utils.CardLocalise.getTranslationName(card, props.globalState.user)
            })
        },
        /*{
            id: 'artist', label: props.intl.formatMessage({ id: "DataGridCardList.Author" }), minWidth: 150, render: ((card) => {
                return 
            })
        },*/
        {
            id: 'manaCost', label: props.intl.formatMessage({ id: "DataGridCardList.Color" }), minWidth: 150, render: ((card) => {

                let manaCost = card?.mana_cost?.replaceAll('{', '').replaceAll('/', '').split('}').filter(e => e.length > 0);
                return <Box sx={{ paddingTop: "4px" }}>
                    {manaCost?.map((mc) => {
                        return <MtgSvg
                            svgName={"svg_" + mc}
                            width="18px" />
                    })}
                </Box>
            })
        },
        /*{
            id: 'originalType', label: props.intl.formatMessage({ id: "DataGridCardList.Type" }), minWidth: 250, render: (card) => {
                return <div>{utils.CardLocalise.getTranslationType(card, props.globalState.settings)}</div>
            }
        },*/
        //  { id: 'formatted_power', label: props.intl.formatMessage({ id: "DataGridCardList.Power" }), minWidth: 150 },
        {
            id: 'rarity', label: props.intl.formatMessage({ id: "DataGridCardList.Rarity" }), width: 64, render: ((card) => {
                return <Box sx={{ width: "100%", height: "100%", textAlign: "center", paddingTop: "4px" }}>
                    <MtgSvg
                        svgName={"svg_" + set.code}
                        class={"rarity_" + card.rarity}
                        height="24px" />
                </Box>
            })
        },

    ];

    if (set.nonfoil_only == true) {
        headers.push({
            id: 'count', label: props.intl.formatMessage({ id: "DataGridCardList.Collection" }), render: (card) => {
                let count = collection.filter((e) => ((e.card_id == card.id) && (e.isFoil == false)));
                return <Box sx={{ width: "100%", height: "100%", textAlign: "center" }}>
                    {count.length > 0 ? count.length : ''}
                </Box>
            }
        });
        headers.push({
            id: 'price', label: props.intl.formatMessage({ id: "DataGridCardList.AveragePrice" }), render: (card) => {
                let price = 0;
                if (card?.prices?.eur != undefined) {
                    price += parseFloat(card?.prices?.eur.toString());
                }

                return <Box sx={{ width: "100%", height: "100%", textAlign: "center" }}>
                    {(price || 0).toFixed(2) + " €"}
                </Box>
            }
        });
    }

    if (set.foil_only == true) {
        headers.push({
            id: 'foilCount', label: props.intl.formatMessage({ id: "DataGridCardList.Foils" }), render: (card) => {
                let count = collection.filter((e) => ((e.uuid == card.uuid) && (e.isFoil == true)));
                return <Box sx={{ width: "100%", height: "100%", textAlign: "center" }}>
                    {count.length > 0 ? count.length : ''}
                </Box>
            }
        });
        headers.push({
            id: 'foilPrice', label: props.intl.formatMessage({ id: "DataGridCardList.AverageFoilPrice" }), render: (card) => {
                let price = 0;
                if (card?.prices?.eur_foil != undefined) {
                    price += parseFloat(card?.prices?.eur_foil.toString());
                }

                return <Box sx={{ width: "100%", height: "100%", textAlign: "center" }}>
                    {(price || 0).toFixed(2) + " €"}
                </Box>
            }
        });
    }

    if (((set.foil_only == false)) && (set.nonfoil_only == false)) {
        headers.push({
            id: 'count', label: props.intl.formatMessage({ id: "DataGridCardList.Collection" }), render: (card) => {
                let count = collection.filter((e) => ((e.card_id == card.id) && (e.isFoil == false)));
                return <Box sx={{ width: "100%", height: "100%", textAlign: "center" }}>
                    {count.length > 0 ? count.length : ''}
                </Box>
            }
        });
        headers.push({
            id: 'price', label: props.intl.formatMessage({ id: "DataGridCardList.AveragePrice" }), render: (card) => {
                let price = 0;
                if (card?.prices?.eur != undefined) {
                    price += parseFloat(card?.prices?.eur?.toString());
                }
                return <Box sx={{ width: "100%", height: "100%", textAlign: "center" }}>
                    {(price || 0).toFixed(2) + " €"}
                </Box>

            }
        });
        headers.push({
            id: 'foilCount', label: props.intl.formatMessage({ id: "DataGridCardList.Foils" }), render: (card) => {
                let count = collection.filter((e) => ((e.uuid == card.uuid) && (e.isFoil == true)));
                return count.length > 0 ? count.length : '';
            }
        });
        headers.push({
            id: 'foilPrice', label: props.intl.formatMessage({ id: "DataGridCardList.AverageFoilPrice" }), render: (card) => {
                let price = 0;
                if (card?.prices?.eur_foil != undefined) {
                    price += parseFloat(card?.prices?.eur_foil?.toString());
                }

                return (price || 0).toFixed(2) + " €";
            }
        });
    }

    headers.push({
        id: 'language', label: props.intl.formatMessage({ id: 'DataGridCardList.inCollectionLanguage' }), flex: 1, render: (card) => {

            Language.forEach((l) => { l.disabled = true });

            if (set && (set.isForeignOnly == undefined || set.isForeignOnly == false) && (card.isAlternative == undefined || card.isAlternative == false)) {
                Language.find((el) => el.language == "English").disabled = false;
            }

            card.lang.forEach((el) => {
                Language.find((e) => e.label == el).disabled = false;
            });

            return <Box>
                {Language.map((l, i) => {
                    if (l.disabled == true) {
                        return <span style={{ paddingLeft: i === 0 ? '0' : '5px' }}>
                            <Button sx={{ fontSize: 12, minWidth: "16px", lineHeight: "initial" }} variant={collection.find((e) => e.card_id == card.id && e.language == l.label) ? "contained" : "outlined"} size="small" disabled={l.disabled} onClick={() => { onLanguageClick(card, l) }}>{l.display}</Button>
                        </span>
                    } else {
                        return <span style={{ paddingLeft: i === 0 ? '0' : '5px' }}>
                            <BootstrapTooltip title={l.language || ""} arrow placement="top">
                                <Button sx={{ fontSize: 12, minWidth: "16px", lineHeight: "initial" }} variant={collection.find((e) => e.card_id == card.id && e.language == l.label) ? "contained" : "outlined"} size="small" disabled={l.disabled} onClick={() => { onLanguageClick(card, l) }}>{l.display}</Button>
                            </BootstrapTooltip>
                        </span>
                    }

                })}
            </Box>
        }
    });

    let filteredCard = [];
    cards.map((card) => {
        for (let key in card.name) {
            let f = card.name[key];
            if (f.toUpperCase().includes(filter.toUpperCase())) {
                if (filteredCard.find((e) => e.id == card.id) == undefined) {
                    filteredCard.push(card);
                }
            }
        }
    });

    return <Box sx={{ display: "grid" }}>

        <Loader display={displayLoader} />

        <Grid container >
            <Grid size={6}>
                <Typography component="div" variant="h5" noWrap>
                    {utils.ExpansionLocalise.getTranslationName(set, props.globalState.settings)}
                </Typography>
            </Grid>
            <Grid size={6} sx={{ textAlign: "right" }}>
                {(() => {
                    function _normal() {
                        return "" + totalCollectionCardCount + " (" + inCollectionCardCount + ") / " + set.card_count + " " + props.intl.formatMessage({ id: "DataGridCardList.Cards" }) + " - " + totalPrice.toFixed(2) + "€ /  " + (set?.prices?.eur || 0).toFixed(2) + "€"
                    }
                    function _foil() {
                        return "" + totalCollectionCardFoilCount + " (" + inCollectionCardFoilCount + ") / " + set.card_count + " " + props.intl.formatMessage({ id: "DataGridCardList.Cards" }) + " - " + totalPriceFoil.toFixed(2) + "€ /  " + (set?.prices?.eur_foil || 0).toFixed(2) + "€"

                    }
                    if ((set.nonfoil_only == true) || (set.foil_only == true)) {
                        return <Typography component="div" variant="h6" noWrap>
                            {(set.nonfoil_only == true) ? _normal() : ((set.foil_only == true) ? _foil() : "")}
                        </Typography>
                    }

                    if ((set.nonfoil_only == false) && (set.foil_only == false)) {
                        return <>
                            <Typography component="div" variant="h6" noWrap>
                                {_normal()}
                            </Typography>
                            <Typography component="div" variant="h6" noWrap>
                                {_foil()}
                            </Typography>
                        </>
                    }
                })()}
            </Grid>
        </Grid >
        <br />
        <Grid container >
            <Grid size={2}>
                <CardFilter
                    onChange={(value) => {
                        setFilter(value)
                    }} />
            </Grid>
        </Grid>

        <br />

        {(filteredCard.length > 0) && <DataGrid
            headers={headers}
            rows={filteredCard}
            onRowClick={(event, card) => {
                // setCardPrint({ visible : true , position: { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } , card});
            }}
            onCellClick={(event, params, card) => {
                if (params.field == 'name' && cardPrint.visible == false) {
                    setCardPrint({ visible: true, position: { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }, card });
                } else {
                    setCardPrint({ visible: false });
                }
            }}
            contexMenu={(item, closeContextMenu) => {
                return <>
                    <MenuItem disabled >
                        <ListItemText >{utils.CardLocalise.getTranslationName(item, props.globalState.user)}</ListItemText>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => {
                        setAddCard({ visible: true, card: item });
                        closeContextMenu();
                    }}>
                        <ListItemIcon>
                            <BookmarkAddIcon />
                        </ListItemIcon >
                        <ListItemText >
                            {props.intl.formatMessage({ id: "ContextMenuItem.AddToCollection" })}
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => {
                        setRemoveCard({ visible: true, card: item });
                        closeContextMenu();
                    }}>
                        <ListItemIcon>
                            <BookmarkRemoveIcon />
                        </ListItemIcon >
                        <ListItemText >
                            {props.intl.formatMessage({ id: "ContextMenuItem.RemoveFromCollection" })}
                        </ListItemText>
                    </MenuItem>
                    <MenuItem disabled={item?.purchase_uris?.cardmarket == undefined} onClick={() => {
                        window.open(item?.purchase_uris?.cardmarket, '_blank');
                    }}>
                        <ListItemIcon>
                            <ShoppingCartCheckoutIcon />
                        </ListItemIcon >
                        <ListItemText >
                            {props.intl.formatMessage({ id: "ContextMenuItem.CardMarketShop" })}
                        </ListItemText>
                    </MenuItem>
                </>
            }}
        />}

        {addCard.visible && <AddCardDialog
            title={props.intl.formatMessage({ id: "ContextMenuItem.AddToCollection" }) + " : " + utils.CardLocalise.getTranslationName(addCard.card, props.globalState.user)}
            card={addCard.card}
            set={set}
            collection={collection}
            languages={Language}
            onClose={() => setAddCard({ ...addCard, visible: false })}
            onValid={async (card, langue, isFoil, note) => {
                try {
                    let coll = await props.dispatch(actions.collection.add(set, card, langue, isFoil, note));
                    setAddCard({ ...addCard, visible: false })
                    setCollection(coll.collection.filter((e) => e.set_code == code));
                } catch (err) {
                    props.snackbar.error(err.message);
                }
            }}
        />}

        {removeCard.visible && <RemoveCarddialog
            title={props.intl.formatMessage({ id: "ContextMenuItem.RemoveFromCollection" }) + " : " + utils.CardLocalise.getTranslationName(removeCard.card, props.globalState.user)}
            card={removeCard.card}
            set={set}
            collection={collection}
            onClose={() => setRemoveCard({ ...removeCard, visible: false })}
            onValid={async (list) => {
                for (let _card of list) {
                    try {
                        let coll = await props.dispatch(actions.collection.remove(_card.id));
                        setRemoveCard({ ...removeCard, visible: false });
                        setCollection(coll.collection.filter((e) => e.set_code == code));
                    } catch (err) {
                        props.snackbar.error(err.message);
                    }
                }
            }}
            languages={Language}
        />}

        {(cardPrint.visible == true) && <DisplayImage
            position={{ x: cardPrint.position.mouseX, y: cardPrint.position.mouseY }}
            height="300px"
            card={cardPrint.card}
        />}
    </Box>
}

export default withStoreProvider(withNavigation(withSnackBar(injectIntl(ExpansionPage))));



