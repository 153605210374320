import * as React from 'react';
import { useGridRootProps } from '../../utils/useGridRootProps';
import { useGridPrivateApiContext } from '../../utils/useGridPrivateApiContext';

const useGridColumnMenuSlots = (props) => {
  const apiRef = useGridPrivateApiContext();
  const rootProps = useGridRootProps();
  const {
    defaultSlots,
    defaultSlotProps,
    slots = {},
    slotProps = {},
    hideMenu,
    colDef,
    addDividers = true,
  } = props;

  const processedComponents = React.useMemo(
    () => ({ ...defaultSlots, ...slots }),
    [defaultSlots, slots],
  );

  const processedSlotProps = React.useMemo(() => {
    if (!slotProps || Object.keys(slotProps).length === 0) {
      return defaultSlotProps;
    }
    const mergedProps = { ...slotProps };
    Object.entries(defaultSlotProps).forEach(([key, currentSlotProps]) => {
      mergedProps[key] = { ...currentSlotProps, ...(slotProps[key] || {}) };
    });
    return mergedProps;
  }, [defaultSlotProps, slotProps]);

  const defaultItems = apiRef.current.unstable_applyPipeProcessors('columnMenu', [], props.colDef);

  const userItems = React.useMemo(() => {
    const defaultComponentKeys = Object.keys(defaultSlots);
    return Object.keys(slots).filter((key) => !defaultComponentKeys.includes(key));
  }, [slots, defaultSlots]);

  return React.useMemo(() => {
    const uniqueItems = Array.from(new Set([...defaultItems, ...userItems]));
    const cleansedItems = uniqueItems.filter((key) => processedComponents[key] != null);
    const sorted = cleansedItems.sort((a, b) => {
      const leftItemProps = processedSlotProps[a];
      const rightItemProps = processedSlotProps[b];
      const leftDisplayOrder = Number.isFinite(leftItemProps?.displayOrder)
        ? leftItemProps.displayOrder
        : 100;
      const rightDisplayOrder = Number.isFinite(rightItemProps?.displayOrder)
        ? rightItemProps.displayOrder
        : 100;
      return leftDisplayOrder - rightDisplayOrder;
    });
    return sorted.reduce((acc, key, index) => {
      let itemProps = { colDef, onClick: hideMenu };
      const processedComponentProps = processedSlotProps[key];
      if (processedComponentProps) {
        const { displayOrder, ...customProps } = processedComponentProps;
        itemProps = { ...itemProps, ...customProps };
      }
      return addDividers && index !== sorted.length - 1
        ? [...acc, [processedComponents[key], itemProps], [rootProps.slots.baseDivider, {}]]
        : [...acc, [processedComponents[key], itemProps]];
    }, []);
  }, [
    addDividers,
    colDef,
    defaultItems,
    hideMenu,
    processedComponents,
    processedSlotProps,
    userItems,
    rootProps.slots.baseDivider,
  ]);
};

export { useGridColumnMenuSlots };
