import { createSelector } from '../../../utils/createSelector';

export const gridFocusStateSelector = (state) => state.focus;

export const gridFocusCellSelector = createSelector(
  gridFocusStateSelector,
  (focusState) => focusState.cell,
);

export const gridFocusColumnHeaderSelector = createSelector(
  gridFocusStateSelector,
  (focusState) => focusState.columnHeader,
);

export const gridFocusColumnHeaderFilterSelector = createSelector(
  gridFocusStateSelector,
  (focusState) => focusState.columnHeaderFilter,
);

export const gridFocusColumnGroupHeaderSelector = createSelector(
  gridFocusStateSelector,
  (focusState) => focusState.columnGroupHeader,
);

export const gridTabIndexStateSelector = (state) => state.tabIndex;

export const gridTabIndexCellSelector = createSelector(
  gridTabIndexStateSelector,
  (state) => state.cell,
);

export const gridTabIndexColumnHeaderSelector = createSelector(
  gridTabIndexStateSelector,
  (state) => state.columnHeader,
);

export const gridTabIndexColumnHeaderFilterSelector = createSelector(
  gridTabIndexStateSelector,
  (state) => state.columnHeaderFilter,
);

export const gridTabIndexColumnGroupHeaderSelector = createSelector(
  gridTabIndexStateSelector,
  (state) => state.columnGroupHeader,
);
