export default {
    getTranslationName: (card, settings) => {
        let text = card?.name[card.lang[0]] || '';
        if (settings.translateInLocale === true) {
            let data = card?.name[settings.locale];
            if (data) {
                text = data ? data : text;
            }
        }
        return text;
    },
    getTranslationType: (card, settings) => {
        let text = card?.originalType || '';
        if (settings.translateInLocale === true) {
            let data = card?.languages || [];
            let locale = data.find((el) => el.language === settings.locale);
            if (locale) {
                text = locale.type ? locale.type : text;
            }
        }
        return text;
    }
}