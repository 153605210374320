import { injectIntl } from 'react-intl';

import TextField from '@mui/material/TextField';


function CardFilter(props) {

    const intl = props.intl;

    let timeout = undefined;

    return <>
        <TextField
            fullWidth
            size="small"
            name="filterCard"
            label={intl.formatMessage({ id: "MenuItem.Search" })}
            variant="outlined"
            onChange={(event) => {
                if (timeout != undefined) {
                    clearTimeout(timeout);
                    timeout = undefined;
                }
                timeout = setTimeout(() => {
                    props.onChange && props.onChange(event.target.value);
                }, props.delay ? props.delay : 1000)

            }}
        />
    </>
}

export default injectIntl(CardFilter);