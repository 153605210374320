import * as React from 'react';
import MUIMenuItem from '@mui/material/MenuItem';

export default function MUISelectOption({
  native,
  ...props
}) {
  if (native) {
    return <option {...props} />;
  }
  return <MUIMenuItem {...props} />;
}
