import actions from "./actions";
import ExpansionLocalise from './expansion.localise';
import BlockLocalise from './block.localise';
import CardLocalise from './card.localise';
import Colors from './colors';
import MesureText from './mesure.text';

export default {
    actions,
    ExpansionLocalise,
    CardLocalise,
    BlockLocalise,
    Colors,
    MesureText,
    sleep : (time) => {
        return new Promise((resolve) => {
            setTimeout(()=>{
                resolve();
            }, time)
        })
    }
}