import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { withNavigation } from '@remyar/react-navigation';
import { withStoreProvider } from '@remyar/react-store';

import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';

import DatasetIcon from '@mui/icons-material/Dataset';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CollectionsIcon from '@mui/icons-material/Collections';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { styled } from '@mui/material/styles';

import packageJson from '../../../package.json';
import Router from '../../routes';
import actions from '../../actions';
import urlSettings from '../../routes/url.settings';
import urlSearch from '../../routes/url.search';
import urlBlocks from '../../routes/url.blocks';
import urlSets from '../../routes/url.sets';
import urlCollection from '../../routes/url.collection';
import urlStats from '../../routes/url.stats';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const MyDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        variants: [
            {
                props: ({ open }) => open,
                style: {
                    ...openedMixin(theme),
                    '& .MuiDrawer-paper': openedMixin(theme),
                },
            },
            {
                props: ({ open }) => !open,
                style: {
                    ...closedMixin(theme),
                    '& .MuiDrawer-paper': closedMixin(theme),
                },
            },
        ],
    }),
);

function Drawer(props) {

    const [open, setOpen] = useState(false);

    const title = props.title ? props.title : (packageJson.name.toUpperCase()/* + ' ' + packageJson.version*/);
    const intl = props.intl;
    let disabled = props.disabled;

    let focusTexField = false
    const toggleDrawer = () => {
        if (focusTexField == false) {
            setOpen(!open);
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        props.navigation.goBack();
        props.navigation.push(Router.urlSearch(event.currentTarget.elements.searchText.value));
    }


    return <MyDrawer variant="permanent" open={open}>
        <ListItem disablePadding onClick={toggleDrawer}>
            <ListItemButton sx={[{ minHeight: 48, px: 2.5, }, open ? { justifyContent: 'initial', } : { justifyContent: 'center', },]} >
                <ListItemIcon sx={[{ minWidth: 0, justifyContent: 'center' }, open ? { mr: 3, } : { mr: 'auto', }]} >
                    <MenuIcon />
                </ListItemIcon>
                <ListItemText primary={title} sx={[open ? { opacity: 1 } : { opacity: 0, }]} />
            </ListItemButton>
        </ListItem>
        <Divider />
        <List>
            <ListItem disablePadding sx={{ borderLeft: props.navigation.getPath().includes(urlSearch("")) ? "solid 5px grey" : "" }}>
                <ListItemButton disabled={disabled} sx={[{ minHeight: 48, px: 2.5, }, open ? { justifyContent: 'initial', } : { justifyContent: 'center', },]} onClick={toggleDrawer}>
                    <ListItemIcon sx={[{ minWidth: 0, justifyContent: 'center' }, open ? { mr: 3, } : { mr: 'auto', }]} >
                        <SearchIcon />
                    </ListItemIcon>
                    <ListItemText primary={
                        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                            <TextField
                                size="small"
                                id="searchText"
                                name="searchText"
                                label={intl.formatMessage({ id: "MenuItem.Search" })}
                                variant="outlined"
                                onFocus={() => { focusTexField = true }}
                                onBlur={() => { focusTexField = false }}
                            />
                        </form>
                    } sx={[open ? { opacity: 1 } : { opacity: 0, }]} />
                </ListItemButton>
            </ListItem>

            <ListItem disablePadding sx={{ borderLeft: props.navigation.getPath().includes(urlBlocks()) ? "solid 5px grey" : "" }} >
                <ListItemButton disabled={disabled} sx={[{ minHeight: 48, px: 2.5, }, open ? { justifyContent: 'initial', } : { justifyContent: 'center', },]} onClick={() => { props.navigation.push(Router.urlBlocks()) }}>
                    <ListItemIcon sx={[{ minWidth: 0, justifyContent: 'center' }, open ? { mr: 3, } : { mr: 'auto', }]} >
                        <DatasetIcon />
                    </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({ id: "MenuItem.Blocks" })} sx={[open ? { opacity: 1 } : { opacity: 0, }]} />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ borderLeft: props.navigation.getPath().includes(urlSets()) ? "solid 5px grey" : "" }} >
                <ListItemButton disabled={disabled} sx={[{ minHeight: 48, px: 2.5, }, open ? { justifyContent: 'initial', } : { justifyContent: 'center', },]} onClick={() => { props.navigation.push(Router.urlSets()) }}>
                    <ListItemIcon sx={[{ minWidth: 0, justifyContent: 'center' }, open ? { mr: 3, } : { mr: 'auto', }]} >
                        <DatasetIcon />
                    </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({ id: "MenuItem.Sets" })} sx={[open ? { opacity: 1 } : { opacity: 0, }]} />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ borderLeft: props.navigation.getPath().includes(urlCollection()) ? "solid 5px grey" : "" }} >
                <ListItemButton disabled={disabled} sx={[{ minHeight: 48, px: 2.5, }, open ? { justifyContent: 'initial', } : { justifyContent: 'center', },]} onClick={() => { props.navigation.push(Router.urlCollection()) }}>
                    <ListItemIcon sx={[{ minWidth: 0, justifyContent: 'center' }, open ? { mr: 3, } : { mr: 'auto', }]} >
                        <CollectionsIcon />
                    </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({ id: "MenuItem.Collection" })} sx={[open ? { opacity: 1 } : { opacity: 0, }]} />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ borderLeft: props.navigation.getPath().includes(urlStats()) ? "solid 5px grey" : "" }} >
                <ListItemButton disabled={disabled} sx={[{ minHeight: 48, px: 2.5, }, open ? { justifyContent: 'initial', } : { justifyContent: 'center', },]} onClick={() => { props.navigation.push(Router.urlStats()) }}>
                    <ListItemIcon sx={[{ minWidth: 0, justifyContent: 'center' }, open ? { mr: 3, } : { mr: 'auto', }]} >
                        <AnalyticsIcon />
                    </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({ id: "MenuItem.Statistics" })} sx={[open ? { opacity: 1 } : { opacity: 0, }]} />
                </ListItemButton>
            </ListItem>





            
        </List>
        <List sx={{ position: "absolute", bottom: "0px", width: "100%" }}>
            <ListItem disablePadding sx={{ borderLeft: props.navigation.getPath().includes(urlSettings()) ? "solid 5px grey" : ""}}>
                <ListItemButton disabled={disabled} sx={[{ minHeight: 48, px: 2.5, }, open ? { justifyContent: 'initial', } : { justifyContent: 'center', },]} onClick={async () => { props.navigation.push(Router.urlSettings()) }}>
                    <ListItemIcon sx={[{ minWidth: 0, justifyContent: 'center' }, open ? { mr: 3, } : { mr: 'auto', }]} >
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({ id: "MenuItem.Settings" })} sx={[open ? { opacity: 1 } : { opacity: 0, }]} />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'block' }} onClick={async () => {
                try {
                    await props.dispatch(actions.user.logout());
                } catch (err) {

                } finally {
                    props.navigation.push(Router.urlIndex())
                }
            }}>
                <ListItemButton sx={[{ minHeight: 48, px: 2.5, }, open ? { justifyContent: 'initial', } : { justifyContent: 'center', },]} >
                    <ListItemIcon sx={[{ minWidth: 0, justifyContent: 'center' }, open ? { mr: 3, } : { mr: 'auto', }]} >
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({ id: "MenuItem.Logout" })} sx={[open ? { opacity: 1 } : { opacity: 0, }]} />
                </ListItemButton>
            </ListItem>
        </List>
    </MyDrawer>
}


export default withStoreProvider(withNavigation(injectIntl(Drawer)));