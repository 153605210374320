const warnedOnceCache = new Set();

// TODO move to @base_ui/internals. Base UI, etc. need this helper.
export function warnOnce(message, gravity = 'warning') {
    if (process.env.NODE_ENV === 'production') {
        return;
    }

    const cleanMessage = Array.isArray(message) ? message.join('\n') : message;

    if (!warnedOnceCache.has(cleanMessage)) {
        warnedOnceCache.add(cleanMessage);

        if (gravity === 'error') {
            console.error(cleanMessage);
        } else {
            console.warn(cleanMessage);
        }
    }
}

export function clearWarningsCache() {
    warnedOnceCache.clear();
}