import * as React from 'react';
import PropTypes from 'prop-types';
import { GridPanelWrapper } from './GridPanelWrapper';
import { GridColumnsManagement } from '../columnsManagement';
import { useGridRootProps } from '../../hooks/utils/useGridRootProps';

function GridColumnsPanel(props) {
  const rootProps = useGridRootProps();
  return (
    <GridPanelWrapper {...props}>
      <GridColumnsManagement {...rootProps.slotProps?.columnsManagement} />
    </GridPanelWrapper>
  );
}

GridColumnsPanel.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // | To update them edit the TypeScript types and run "pnpm proptypes"  |
  // ----------------------------------------------------------------------
  slotProps: PropTypes.object,
};

export { GridColumnsPanel };
