
export class Store {
  value;

  listeners;

  static create(value) {
    return new Store(value);
  }

  constructor(value) {
    this.value = value;
    this.listeners = new Set();
  }

  subscribe = (fn) => {
    this.listeners.add(fn);
    return () => {
      this.listeners.delete(fn);
    };
  };

  getSnapshot = () => {
    return this.value;
  };

  update = (value) => {
    this.value = value;
    this.listeners.forEach((l) => l(value));
  };
}
