import {
  unstable_generateUtilityClasses as generateUtilityClasses,
  unstable_generateUtilityClass as generateUtilityClass,
} from '@mui/utils';

export function getDataGridUtilityClass(slot) {
  return generateUtilityClass('MuiDataGrid', slot);
}

export const gridClasses = generateUtilityClasses('MuiDataGrid', [
  'actionsCell',
  'aggregationColumnHeader',
  'aggregationColumnHeader--alignLeft',
  'aggregationColumnHeader--alignCenter',
  'aggregationColumnHeader--alignRight',
  'aggregationColumnHeaderLabel',
  'autoHeight',
  'autosizing',
  'booleanCell',
  'cell--editable',
  'cell--editing',
  'cell--flex',
  'cell--textCenter',
  'cell--textLeft',
  'cell--textRight',
  'cell--rangeTop',
  'cell--rangeBottom',
  'cell--rangeLeft',
  'cell--rangeRight',
  'cell--pinnedLeft',
  'cell--pinnedRight',
  'cell--selectionMode',
  'cell',
  'cellCheckbox',
  'cellEmpty',
  'cellSkeleton',
  'cellOffsetLeft',
  'checkboxInput',
  'columnHeader',
  'columnHeader--alignCenter',
  'columnHeader--alignLeft',
  'columnHeader--alignRight',
  'columnHeader--dragging',
  'columnHeader--moving',
  'columnHeader--numeric',
  'columnHeader--sortable',
  'columnHeader--sorted',
  'columnHeader--filtered',
  'columnHeader--pinnedLeft',
  'columnHeader--pinnedRight',
  'columnHeader--last',
  'columnHeader--lastUnpinned',
  'columnHeader--siblingFocused',
  'columnHeaderCheckbox',
  'columnHeaderDraggableContainer',
  'columnHeaderTitle',
  'columnHeaderTitleContainer',
  'columnHeaderTitleContainerContent',
  'columnHeader--filledGroup',
  'columnHeader--emptyGroup',
  'columnHeaders',
  'columnSeparator--resizable',
  'columnSeparator--resizing',
  'columnSeparator--sideLeft',
  'columnSeparator--sideRight',
  'columnSeparator',
  'columnsManagement',
  'columnsManagementRow',
  'columnsManagementHeader',
  'columnsManagementFooter',
  'container--top',
  'container--bottom',
  'detailPanel',
  'detailPanels',
  'detailPanelToggleCell',
  'detailPanelToggleCell--expanded',
  'footerCell',
  'panel',
  'panelHeader',
  'panelWrapper',
  'panelContent',
  'panelFooter',
  'paper',
  'editBooleanCell',
  'editInputCell',
  'filler',
  'filler--borderBottom',
  'filler--pinnedLeft',
  'filler--pinnedRight',
  'filterForm',
  'filterFormDeleteIcon',
  'filterFormLogicOperatorInput',
  'filterFormColumnInput',
  'filterFormOperatorInput',
  'filterFormValueInput',
  'filterIcon',
  'footerContainer',
  'headerFilterRow',
  'iconButtonContainer',
  'iconSeparator',
  'main',
  'main--hasPinnedRight',
  'main--hasSkeletonLoadingOverlay',
  'menu',
  'menuIcon',
  'menuIconButton',
  'menuOpen',
  'menuList',
  'overlay',
  'overlayWrapper',
  'overlayWrapperInner',
  'root',
  'root--densityStandard',
  'root--densityComfortable',
  'root--densityCompact',
  'root--disableUserSelection',
  'root--noToolbar',
  'row',
  'row--editable',
  'row--editing',
  'row--firstVisible',
  'row--lastVisible',
  'row--dragging',
  'row--dynamicHeight',
  'row--detailPanelExpanded',
  'row--borderBottom',
  'rowReorderCellPlaceholder',
  'rowCount',
  'rowReorderCellContainer',
  'rowReorderCell',
  'rowReorderCell--draggable',
  'rowSkeleton',
  'scrollArea--left',
  'scrollArea--right',
  'scrollArea',
  'scrollbar',
  'scrollbar--vertical',
  'scrollbar--horizontal',
  'scrollbarFiller',
  'scrollbarFiller--header',
  'scrollbarFiller--borderTop',
  'scrollbarFiller--borderBottom',
  'scrollbarFiller--pinnedRight',
  'selectedRowCount',
  'sortIcon',
  'toolbarContainer',
  'toolbarFilterList',
  'virtualScroller',
  'virtualScroller--hasScrollX',
  'virtualScrollerContent',
  'virtualScrollerContent--overflowed',
  'virtualScrollerRenderZone',
  'pinnedColumns',
  'withVerticalBorder',
  'withBorderColor',
  'cell--withRightBorder',
  'cell--withLeftBorder',
  'columnHeader--withRightBorder',
  'columnHeader--withLeftBorder',
  'treeDataGroupingCell',
  'treeDataGroupingCellToggle',
  'treeDataGroupingCellLoadingContainer',
  'groupingCriteriaCell',
  'groupingCriteriaCellToggle',
  'groupingCriteriaCellLoadingContainer',
  'pinnedRows',
  'pinnedRows--top',
  'pinnedRows--bottom',
  'pinnedRowsRenderZone',
]);
