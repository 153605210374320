import utils from '../../utils';

export default {
    login: utils.actions(async (user, { extra, getState }) => {
        try {
            let result = await extra.api.method.post("/api/v1/public/user/login", { ...user });
            return { user: result.user };
        } catch (err) {
            throw { message: err.message };
        }
    }),
    signup: utils.actions(async (user, { extra, getState }) => {
        try {
            let result = await extra.api.method.post("/api/v1/public/user/create", { ...user });
            return { user: result.user };
        } catch (err) {
            throw { message: err.message };
        }
    }),
    getSession: utils.actions(async ({ extra }) => {
        try {
            let result = await extra.api.method.get("/api/v1/public/user/session");
            return { user: result.user, message: result.message };
        } catch (err) {
            throw { message: err.message };
        }
    }),
    logout: utils.actions(async ({ extra }) => {
        try {
            let result = await extra.api.method.post("/api/v1/private/user/logout");
            return { user: undefined };
        } catch (err) {
            throw { message: err.message };
        }
    }),
    setSettings: utils.actions(async (settings , { extra }) => {
        try {
            let result = await extra.api.method.post("/api/v1/private/user/settings", { ...settings });
            return { user: result.user };
        } catch (err) {
            throw { message: err.message };
        }
    }),
}