import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { withNavigation } from '@remyar/react-navigation';
import { withStoreProvider } from '@remyar/react-store';
import { withSnackBar } from '@remyar/react-snackbar';
import { useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';


import Loader from '../../components/loader';
import DataGrid from '../../components/datagrid';

import actions from '../../actions';
import utils from '../../utils';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

function ExpansionPage(props) {

    const { uuid } = useParams();

    const [displayLoader, setDisplayLoader] = useState(false);
    const [expansion, setExpansion] = useState(undefined);
    const [collection, setCollection] = useState([]);

    async function fetchData() {
        try {
            setDisplayLoader(true);
            let result = await props.dispatch(actions.expansion.get(uuid));
            if (result.expansion) {
                setExpansion(result.expansion);
            }
        } catch (err) {
            props.snackbar.error(err.message);
        } finally {
            setDisplayLoader(false);
        }
    }

    async function onLanguageClick(card, language) {
        try {
            await props.dispatch(actions.collection.addNormal(card, expansion, language.label));

            /*
            let c = collection.find((el) => el.uuid == card.uuid);
            if (c != undefined) {
                if (c.language == undefined) {
                    c.language = [];
                }
                if (c.language?.find((lan) => lan.language == language.language) == undefined) {
                    c.language.push(language);
                } else {
                    let temp = [...c.language];
                    c.language = [];
                    temp.map((el) => {
                        if (el.language != language.language) {
                            c.language.push(el);
                        }
                    })
                }
                await props.dispatch(actions.collection.addNormal(card, expansion, c.language));
            }
            else {
                await props.dispatch(actions.collection.addNormal(card, expansion, language));
            }*/
        } catch (err) {
            props.snackbar.error(err.message);
        } finally {

        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    let cards = expansion?.cards || [];
    let Language = [
        { label: "en", language: "English", disabled: true },
        { label: "es", language: "Spanish", disabled: true },
        { label: "fr", language: "French", disabled: true },
        { label: "de", language: "German", disabled: true },
        { label: "it", language: "Italian", disabled: true },
        { label: "pt", language: "Portuguese (Brazil)", disabled: true },
        { label: "ja", language: "Japanese", disabled: true },
        { label: "ko", language: "Korean", disabled: true },
        { label: "ru", language: "Russian", disabled: true },
        { label: "汉语", language: "Chinese Simplified", disabled: true },
        { label: "漢語", language: "Chinese Traditional", disabled: true }
    ];

    const headers = [
        { id: 'number', label: props.intl.formatMessage({ id: "DataGridCardList.Number" }) },
        { id: 'locale_name', label: props.intl.formatMessage({ id: "DataGridCardList.Name" }) },
        { id: 'artist', label: props.intl.formatMessage({ id: "DataGridCardList.Author" }) },
        {
            id: 'formatted_color', label: props.intl.formatMessage({ id: "DataGridCardList.Color" }), render: (card) => {
                let color = utils.Colors.getColorTextFromURI(card.formatted_color);
                let text = (color.length > 0) ? props.intl.formatMessage({ id: "Colors." + color }) : color;
                return <div style={{ display: "flex" }} key={"text" + new Date().getTime()}>
                    <div style={{ width: "20px" }} dangerouslySetInnerHTML={{ __html: card?.formatted_color_svg }} />
                    <div style={{ paddingLeft: '7px' }}>{card.formatted_color.length > 0 ? text : ''}</div>
                </div>
            }
        },
        { id: 'originalType', label: props.intl.formatMessage({ id: "DataGridCardList.Type" }) },
        { id: 'formatted_power', label: props.intl.formatMessage({ id: "DataGridCardList.Power" }) },
        { id: 'rarity', label: props.intl.formatMessage({ id: "DataGridCardList.Rarity" }) },
        {
            id: 'language', label: props.intl.formatMessage({ id: 'DataGridCardList.inCollectionLanguage' }), render: (card) => {

                Language = Language.map((l) => {
                    l.className = true;
                    return l;
                });

                if (expansion && (expansion.isForeignOnly == undefined || expansion.isForeignOnly == false) && (card.isAlternative == undefined || card.isAlternative == false)) {
                    Language.find((el) => el.language == "English").disabled = false;
                }

                card.foreignData.forEach((el) => {
                    Language.find((e) => e.language == el.language).disabled = false;
                });

                return <Box>
                    {Language.map((l, i) => {
                        return <span style={{ paddingLeft: i == 0 ? '0' : '5px' }}>
                            <BootstrapTooltip title={l.language || ""} arrow placement="top">
                                <Button sx={{ fontSize: 12, minWidth: "16px", lineHeight: "initial" }} variant="contained" size="small" disabled={l.disabled} onClick={() => { onLanguageClick(card, l) }}>{l.label}</Button>
                            </BootstrapTooltip>
                        </span>
                    })}
                </Box>
            }
        }
    ];

    return <Box>
        <Loader display={displayLoader} />

        <DataGrid
            headers={headers}
            rows={cards}
        />

    </Box>
}

export default withStoreProvider(withNavigation(withSnackBar(injectIntl(ExpansionPage))));