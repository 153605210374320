import { injectIntl } from 'react-intl';
import { withNavigation } from '@remyar/react-navigation';
import { withStoreProvider } from '@remyar/react-store';
import { withSnackBar } from '@remyar/react-snackbar';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import Router from '../../routes';
import actions from '../../actions';

const SignupSchema = Yup.object().shape({
    username: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    password: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required')
});

function LoginPage(props) {

    const intl = props.intl;

    return <Box >
        <Box sx={{
            position: 'absolute',
            left: '50%',
            top: '40%',
            width: "80%",
            transform: 'translate(-50%, -40%)',
            textAlign: 'center'
        }}>
            <Formik initialValues={{ username: '', password: '' }} validationSchema={SignupSchema} onSubmit={async (values, { setSubmitting }) => {
                try{
                    let result = await props.dispatch(actions.user.login(values));
                    if (result.user) {
                        if (result.user.active == true) {
                            try {
                                await props.dispatch(actions.settings.getAll());
                            } catch (err) {

                            }
                            props.navigation.push(Router.urlIndex());
                        } else {
                            props.snackbar.info(intl.formatMessage({ id: "user.activation.waiting" }));
                        }
                    }
                }catch(err){
                    props.snackbar.error(intl.formatMessage({ id: err.message }));
                } finally {
                    setSubmitting(false);
                }
            }}>
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main', textAlign: 'center' }}>
                                <LockOutlinedIcon />
                            </Avatar>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid size={4} sx={{ textAlign: 'center' }} />
                            <Grid size={4} sx={{ textAlign: 'center' }}>
                                <Typography component="h1" variant="h5">
                                    {intl.formatMessage({ id: 'Login.singin' })}
                                </Typography>
                            </Grid>
                            <Grid size={4} sx={{ textAlign: 'center' }} />
                        </Grid>
                        <Grid container spacing={2} sx={{ paddingTop: '15px' }}>
                            <Grid size={4} sx={{ textAlign: 'center' }} />
                            <Grid size={4} sx={{ textAlign: 'center' }}>
                                {/*errors.username && touched.username ? (
                                    <div>{errors.username}</div>
                                ) : null*/}
                                <TextField type="text" name="username" label={intl.formatMessage({ id: 'Login.username' })} variant="outlined" sx={{ width: "100%", textAlign: "center" }} onChange={handleChange} />
                                <ErrorMessage name="username">{msg => props.snackbar.error(intl.formatMessage({ id: msg }))}</ErrorMessage>
                            </Grid>
                            <Grid size={4} sx={{ textAlign: 'center' }} />
                        </Grid>
                        <Grid container spacing={2} sx={{ paddingTop: '15px' }}>
                            <Grid size={4} sx={{ textAlign: 'center' }} />
                            <Grid size={4} sx={{ textAlign: 'center' }}>
                                {/*errors.password && touched.password ? (
                                    <div>{errors.password}</div>
                                ) : null*/}
                                <TextField type="text" name="password" label={intl.formatMessage({ id: 'Login.password' })} variant="outlined" sx={{ width: "100%", textAlign: "center" }} onChange={handleChange} />
                                <ErrorMessage name="password">{msg => props.snackbar.error(intl.formatMessage({ id: msg }))}</ErrorMessage>

                            </Grid>
                            <Grid size={4} sx={{ textAlign: 'center' }} />
                        </Grid>
                        <Grid container spacing={2} sx={{ paddingTop: '10px' }}>
                            <Grid size={4} sx={{ textAlign: 'center' }} />
                            <Grid size={4} sx={{ textAlign: 'center' }}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    fullWidth
                                    disabled={isSubmitting}
                                >{intl.formatMessage({ id: 'Button.validate' })}</Button>
                            </Grid>
                            <Grid size={4} sx={{ textAlign: 'center' }} />
                        </Grid>
                        <Grid container sx={{ paddingTop: '10px' }}>
                            <Grid size={4} />
                            <Grid size={2} >
                                <Link component="button"
                                    onClick={() => {
                                        props.navigation.push(Router.urlForgotPassword());
                                    }} variant="body2">
                                    {intl.formatMessage({ id: 'Login.password.forgot' })}
                                </Link>
                            </Grid>
                            <Grid size={2} >
                                <Link component="button" onClick={() => {
                                    props.navigation.push(Router.urlSignup());
                                }} variant="body2">
                                    {intl.formatMessage({ id: 'Login.account.signup' })}
                                </Link>
                            </Grid>
                            <Grid size={4} />
                        </Grid>
                    </form>
                )}
            </Formik>
        </Box>
    </Box >
}

export default withStoreProvider(withNavigation(withSnackBar(injectIntl(LoginPage))));