import * as React from 'react';
import composeClasses from '@mui/utils/composeClasses';
import { styled } from '@mui/material/styles';
import {
    getDataGridUtilityClass,
    gridClasses,
    GridColumnHeaderTitle,
} from '../../x-data-grid';
import { getAggregationFunctionLabel } from '../hooks/features/aggregation/gridAggregationUtils';
import { useGridApiContext } from '../hooks/utils/useGridApiContext';
import { useGridRootProps } from '../hooks/utils/useGridRootProps';

const GridAggregationHeaderRoot = styled('div', {
    name: 'MuiDataGrid',
    slot: 'AggregationColumnHeader',
    overridesResolver: (_, styles) => styles.aggregationColumnHeader,
})({
    display: 'flex',
    flexDirection: 'column',
    [`&.${gridClasses['aggregationColumnHeader--alignRight']}`]: {
        alignItems: 'flex-end',
    },
    [`&.${gridClasses['aggregationColumnHeader--alignCenter']}`]: {
        alignItems: 'center',
    },
});

const GridAggregationFunctionLabel = styled('div', {
    name: 'MuiDataGrid',
    slot: 'AggregationColumnHeaderLabel',
    overridesResolver: (_, styles) => styles.aggregationColumnHeaderLabel,
}) (({ theme }) => {
    return {
        fontSize: theme.typography.caption.fontSize,
        lineHeight: 'normal',
        color: theme.palette.text.secondary,
        marginTop: -1,
    };
});

const useUtilityClasses = (ownerState) => {
    const { classes, colDef } = ownerState;

    const slots = {
        root: [
            'aggregationColumnHeader',
            colDef.headerAlign === 'left' && 'aggregationColumnHeader--alignLeft',
            colDef.headerAlign === 'center' && 'aggregationColumnHeader--alignCenter',
            colDef.headerAlign === 'right' && 'aggregationColumnHeader--alignRight',
        ],
        aggregationLabel: ['aggregationColumnHeaderLabel'],
    };

    return composeClasses(slots, getDataGridUtilityClass, classes);
};

function GridAggregationHeader(props) {
    const { renderHeader, ...params } = props;
    const { colDef, aggregation } = params;

    const apiRef = useGridApiContext();
    const rootProps = useGridRootProps();

    const ownerState = { ...rootProps, classes: rootProps.classes, colDef };
    const classes = useUtilityClasses(ownerState);

    if (!aggregation) {
        return null;
    }

    const aggregationLabel = getAggregationFunctionLabel({
        apiRef,
        aggregationRule: aggregation.aggregationRule,
    });

    return (
        <GridAggregationHeaderRoot ownerState={ownerState} className={classes.root}>
            {renderHeader ? (
                renderHeader(params)
            ) : (
                <GridColumnHeaderTitle
                    label={colDef.headerName ?? colDef.field}
                    description={colDef.description}
                    columnWidth={colDef.computedWidth}
                />
            )}
            <GridAggregationFunctionLabel ownerState={ownerState} className={classes.aggregationLabel}>
                {aggregationLabel}
            </GridAggregationFunctionLabel>
        </GridAggregationHeaderRoot>
    );
}

export { GridAggregationHeader };