import { injectIntl } from 'react-intl';
import { withNavigation } from '@remyar/react-navigation';
import { withStoreProvider } from '@remyar/react-store';
import { withSnackBar } from '@remyar/react-snackbar';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import actions from '../../actions';

import Router from '../../routes';

const SignupSchema = Yup.object().shape({
    username: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    password: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
});

function Signup(props) {

    const intl = props.intl;

    return <Box>
        <Box sx={{
            position: 'absolute',
            left: '50%',
            top: '40%',
            width: "80%",
            transform: 'translate(-50%, -40%)',
            textAlign: 'center'
        }}>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main', textAlign: 'center' }}>
                    <LockOutlinedIcon />
                </Avatar>
            </Box>
            <Grid container spacing={2} sx={{ paddingBottom: '10px' }}>
                <Grid size={4} sx={{ textAlign: 'center' }} />
                <Grid size={4} sx={{ textAlign: 'center' }}>
                    <Typography component="h1" variant="h5">
                        {intl.formatMessage({ id: 'signup.title' })}
                    </Typography>
                </Grid>
                <Grid size={4} sx={{ textAlign: 'center' }} />
            </Grid>
            <Formik
                initialValues={{ email: '', password: '', username: '' }}
                validationSchema={SignupSchema}
                onSubmit={async (values) => {
                    //  setDisplayLoader(true);
                      try {
                          let result = await props.dispatch(actions.user.signup(values));
                          result = await props.dispatch(actions.user.getSession());
                          if (result.message) {
                              props.snackbar.info(intl.formatMessage({ id: result.message }));
                          }
                          await props.dispatch(actions.user.logout());
                          props.navigation.push(Router.urlIndex());
                      } catch (err) {
                          props.snackbar.error(intl.formatMessage({ id: err.message }));
                      } finally {
                       //   setDisplayLoader(false);
                      }
                }}
            >
                {({ errors, touched }) => {
                    return <Form>
                        <Grid container spacing={2}>
                            <Grid size={4} sx={{ textAlign: 'center' }} />
                            <Grid size={4} sx={{ textAlign: 'center' }}>
                                <Field name="username" error={errors.username && touched.username} label={intl.formatMessage({ id: 'signup.username' })} as={TextField} sx={{ width: "100%", textAlign: "center" }} variant="outlined" />
                                <ErrorMessage name="username">{msg => props.snackbar.error(intl.formatMessage({ id: msg }))}</ErrorMessage>
                            </Grid>
                            <Grid size={4} sx={{ textAlign: 'center' }} />
                        </Grid>
                        <br />
                        <Grid container spacing={2}>
                            <Grid size={4} sx={{ textAlign: 'center' }} />
                            <Grid size={4} sx={{ textAlign: 'center' }}>
                                <Field name="password" error={errors.password && touched.password} type="password" label={intl.formatMessage({ id: 'signup.password' })} as={TextField} sx={{ width: "100%", textAlign: "center" }} variant="outlined" />
                                <ErrorMessage name="password">{msg => props.snackbar.error(intl.formatMessage({ id: msg }))}</ErrorMessage>
                            </Grid>
                            <Grid size={4} sx={{ textAlign: 'center' }} />
                        </Grid>
                        <br />
                        <Grid container spacing={2}>
                            <Grid size={4} sx={{ textAlign: 'center' }} />
                            <Grid size={4} sx={{ textAlign: 'center' }}>
                                <Field name="email" error={errors.email && touched.email} type="email" label={intl.formatMessage({ id: 'signup.email' })} as={TextField} sx={{ width: "100%", textAlign: "center" }} variant="outlined" />
                                <ErrorMessage name="email">{msg => props.snackbar.error(intl.formatMessage({ id: msg }))}</ErrorMessage>
                            </Grid>
                            <Grid size={4} sx={{ textAlign: 'center' }} />
                        </Grid>
                        <br />
                        <Grid container spacing={2}>
                            <Grid size={4} sx={{ textAlign: 'center' }} />
                            <Grid size={4} sx={{ textAlign: 'center' }}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    fullWidth >{intl.formatMessage({ id: 'button.validate' })}</Button>
                            </Grid>
                            <Grid size={4} sx={{ textAlign: 'center' }} />
                        </Grid>
                    </Form>
                }}
            </Formik>
        </Box>
    </Box>
}

export default withNavigation(withStoreProvider(withSnackBar(injectIntl(Signup))));