import urlIndex from './url.index';
import urlLogin from './url.login';
import urlSignup from './url.signup';
import urlHome from './url.home';
import urlBlocks from './url.blocks';
import urlSets from './url.sets';
import urlSet from './url.set';
import urlSearch from './url.search';
import urlSettings from './url.settings';
import urlCollection from './url.collection';
import urlCard from './url.card';
import urlStats from './url.stats';

export default {
    urlIndex,
    urlLogin,
    urlSignup,
    urlHome,
    urlBlocks,
    urlSets,
    urlSet,
    urlSettings,
    urlCollection,
    urlSearch,
    urlCard,
    urlStats
}