function _getColorFromCode (code) {
    let text = '';
    switch (code) {
        case 'B':
            text = 'Black';
            break;
        case 'U':
            text = 'Blue';
            break;
        case 'G':
            text = 'Green';
            break;
        case 'W':
            text = 'White';
            break;
        case 'R':
            text = 'Red';
            break;
        case 'C':
            text = 'Multi';
            break;
        default:
            break;
    }
    return text;
}

export default {

    getColorFromCode : (code) => {
       return _getColorFromCode(code);
    },

    getColorText : () => {

    },

    getColorTextFromURI : (uri) => {
        if ( uri ){
            let code = uri.substring(uri.lastIndexOf('/') + 1 , uri.length).replace('.svg','');
            return _getColorFromCode(code);
        } else {
            return '';
        }
        
    },

    getColorSvgUri : (symbologie , color ) => {
        if ( color === "ColorLess"){
            color = "C";
        }
        let c = symbologie?.find((el) => el.loose_variant === color) || {};
        return c.svg_uri || "";
    }

}