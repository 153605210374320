
const GridEditModes = {
  Cell: 'cell',
  Row: 'row',
}

const GridCellModes = {
  Edit: 'edit',
  View: 'view',
}

const GridRowModes = {
  Edit: 'edit',
  View: 'view',
}

export { GridEditModes, GridCellModes, GridRowModes };
