export const GridPinnedColumnPosition = {
  LEFT : 'left',
  RIGHT : 'right',
}

export const EMPTY_PINNED_COLUMN_FIELDS = {
  left: [],
  right: [],
};

