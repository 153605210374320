import * as React from 'react';
import { useGridApiContext } from '../hooks/utils/useGridApiContext';
import { GridOverlay } from './containers/GridOverlay';

export const GridNoResultsOverlay = React.forwardRef(
  function GridNoResultsOverlay(props, ref) {
    const apiRef = useGridApiContext();
    const noResultsOverlayLabel = apiRef.current.getLocaleText('noResultsOverlayLabel');

    return (
      <GridOverlay ref={ref} {...props}>
        {noResultsOverlayLabel}
      </GridOverlay>
    );
  },
);
