import { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { withStoreProvider } from '@remyar/react-store';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import HomePage from './pages/home';
import LoginPage from './pages/login';
import SignupPage from './pages/signup';
import InstallPage from './pages/install';
import BlocksPage from './pages/blocks';
import SetPage from './pages/set';
import ExpansionPage from './pages/expansion';
import SettingsPage from './pages/settings';
import CollectionPage from './pages/collection';
import SetsPage from './pages/sets';
import SearchPage from './pages/search';
import Drawer from './components/drawer';
import CardPage from './pages/card';
import StatsPage from './pages/stats';

import Router from './routes';

const routes = [
    { path: Router.urlIndex(), name: 'Install', Component: <InstallPage /> },
    { path: Router.urlHome(), name: 'Login', Component: <HomePage /> },
    { path: Router.urlLogin(), name: 'Login', Component: <LoginPage /> },
    { path: Router.urlSignup(), name: 'Signup', Component: <SignupPage /> },
    { path: Router.urlSets(), name: 'Sets', Component: <SetsPage /> },
    { path: Router.urlBlocks(), name: 'block', Component: <BlocksPage /> },
    { path: Router.urlSet(":code"), name: 'set', Component: <SetPage /> },
    { path: Router.urlSearch(":word"), name: 'search', Component: <SearchPage /> },
    { path: Router.urlSettings(), name: 'Settings', Component: <SettingsPage /> },
    { path: Router.urlCollection(), name: 'Collection', Component: <CollectionPage /> },
    { path: Router.urlCard(":id"), name: 'Card', Component: <CardPage /> },
    { path: Router.urlStats(), name: 'Card', Component: <StatsPage /> },
];

function App(props) {
    let user = props?.globalState?.user;

    const [locale, setLocale] = useState(undefined);

    if (user.locale != locale) {
        setLocale(user.locale);
        props.onLocaleChange && props.onLocaleChange(user.locale);
    }

    return <Box sx={{ display: 'flex' }} >
        <Drawer disabled={user == undefined} />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Routes >
                {routes.map(({ path, Component }) => (
                    <Route path={path} key={path} element={Component} />
                ))}
            </Routes>
        </Box>
        <Box sx={{ position: "absolute", visibility: "hidden", height: "auto", width: "auto", whiteSpace: "nowrap" }} id="Test"></Box>
    </Box>;
}

export default withStoreProvider(App);