import * as React from 'react';
import { useGridApiMethod } from '../../utils/useGridApiMethod';

export const EMPTY_RENDER_CONTEXT = {
  firstRowIndex: 0,
  lastRowIndex: 0,
  firstColumnIndex: 0,
  lastColumnIndex: 0,
};

export const virtualizationStateInitializer = (state, props) => {
  const { disableVirtualization, autoHeight } = props;

  const virtualization = {
    enabled: !disableVirtualization,
    enabledForColumns: !disableVirtualization,
    enabledForRows: !disableVirtualization && !autoHeight,
    renderContext: EMPTY_RENDER_CONTEXT,
  };

  return {
    ...state,
    virtualization,
  };
};

export function useGridVirtualization(
  apiRef,
  props,
) {
  /*
   * API METHODS
   */

  const setVirtualization = (enabled) => {
    apiRef.current.setState((state) => ({
      ...state,
      virtualization: {
        ...state.virtualization,
        enabled,
        enabledForColumns: enabled,
        enabledForRows: enabled && !props.autoHeight,
      },
    }));
  };

  const setColumnVirtualization = (enabled) => {
    apiRef.current.setState((state) => ({
      ...state,
      virtualization: {
        ...state.virtualization,
        enabledForColumns: enabled,
      },
    }));
  };

  const api = {
    unstable_setVirtualization: setVirtualization,
    unstable_setColumnVirtualization: setColumnVirtualization,
  };

  useGridApiMethod(apiRef, api, 'public');

  /*
   * EFFECTS
   */

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    setVirtualization(!props.disableVirtualization);
  }, [props.disableVirtualization, props.autoHeight]);
  /* eslint-enable react-hooks/exhaustive-deps */
}
