import * as React from 'react';
import {
  getGridCellElement,
  getGridColumnHeaderElement,
  getGridRowElement,
} from '../../../utils/domUtils';
import { useGridApiMethod } from '../../utils/useGridApiMethod';
import { gridFocusCellSelector, gridTabIndexCellSelector } from '../focus/gridFocusStateSelector';
import { gridListColumnSelector } from '../listView/gridListViewSelectors';

export class MissingRowIdError extends Error { }

export function useGridParamsApi(
  apiRef,
  props,
) {
  const getColumnHeaderParams = React.useCallback(
    (field) => ({
      field,
      colDef: apiRef.current.getColumn(field),
    }),
    [apiRef],
  );

  const getRowParams = React.useCallback(
    (id) => {
      const row = apiRef.current.getRow(id);

      if (!row) {
        throw new MissingRowIdError(`No row with id #${id} found`);
      }

      const params = {
        id,
        columns: apiRef.current.getAllColumns(),
        row,
      };
      return params;
    },
    [apiRef],
  );

  const getCellParams = React.useCallback(
    (id, field) => {
      const colDef = (
        props.unstable_listView
          ? gridListColumnSelector(apiRef.current.state)
          : apiRef.current.getColumn(field)
      );
      const row = apiRef.current.getRow(id);
      const rowNode = apiRef.current.getRowNode(id);

      if (!row || !rowNode) {
        throw new MissingRowIdError(`No row with id #${id} found`);
      }

      const rawValue = row[field];
      const value = colDef?.valueGetter
        ? colDef.valueGetter(rawValue, row, colDef, apiRef)
        : rawValue;
      const cellFocus = gridFocusCellSelector(apiRef);
      const cellTabIndex = gridTabIndexCellSelector(apiRef);

      const params = {
        id,
        field,
        row,
        rowNode,
        colDef,
        cellMode: apiRef.current.getCellMode(id, field),
        hasFocus: cellFocus !== null && cellFocus.field === field && cellFocus.id === id,
        tabIndex: cellTabIndex && cellTabIndex.field === field && cellTabIndex.id === id ? 0 : -1,
        value,
        formattedValue: value,
        isEditable: false,
        api: {},
      };
      if (colDef && colDef.valueFormatter) {
        params.formattedValue = colDef.valueFormatter(value, row, colDef, apiRef);
      }
      params.isEditable = colDef && apiRef.current.isCellEditable(params);

      return params;
    },
    [apiRef, props.unstable_listView],
  );

  const getCellValue = React.useCallback(
    (id, field) => {
      const colDef = apiRef.current.getColumn(field);

      const row = apiRef.current.getRow(id);
      if (!row) {
        throw new MissingRowIdError(`No row with id #${id} found`);
      }

      if (!colDef || !colDef.valueGetter) {
        return row[field];
      }

      return colDef.valueGetter(row[colDef.field], row, colDef, apiRef);
    },
    [apiRef],
  );

  const getRowValue = React.useCallback(
    (row, colDef) => {
      const field = colDef.field;

      if (!colDef || !colDef.valueGetter) {
        return row[field];
      }

      const value = row[colDef.field];
      return colDef.valueGetter(value, row, colDef, apiRef);
    },
    [apiRef],
  );

  const getRowFormattedValue = React.useCallback(
    (row, colDef) => {
      const value = getRowValue(row, colDef);

      if (!colDef || !colDef.valueFormatter) {
        return value;
      }

      return colDef.valueFormatter(value, row, colDef, apiRef);
    },
    [apiRef, getRowValue],
  );

  const getColumnHeaderElement = React.useCallback(
    (field) => {
      if (!apiRef.current.rootElementRef.current) {
        return null;
      }
      return getGridColumnHeaderElement(apiRef.current.rootElementRef.current, field);
    },
    [apiRef],
  );
  const getRowElement = React.useCallback(
    (id) => {
      if (!apiRef.current.rootElementRef.current) {
        return null;
      }
      return getGridRowElement(apiRef.current.rootElementRef.current, id);
    },
    [apiRef],
  );

  const getCellElement = React.useCallback(
    (id, field) => {
      if (!apiRef.current.rootElementRef.current) {
        return null;
      }
      return getGridCellElement(apiRef.current.rootElementRef.current, { id, field });
    },
    [apiRef],
  );

  const paramsApi = {
    getCellValue,
    getCellParams,
    getCellElement,
    getRowValue,
    getRowFormattedValue,
    getRowParams,
    getRowElement,
    getColumnHeaderParams,
    getColumnHeaderElement,
  };

  useGridApiMethod(apiRef, paramsApi, 'public');
}
