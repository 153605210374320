
const GridCellEditStartReasons = {
  enterKeyDown: 'enterKeyDown',
  cellDoubleClick: 'cellDoubleClick',
  printableKeyDown: 'printableKeyDown',
  deleteKeyDown: 'deleteKeyDown',
  pasteKeyDown: 'pasteKeyDown',
}

const GridCellEditStopReasons = {
  cellFocusOut: 'cellFocusOut',
  escapeKeyDown: 'escapeKeyDown',
  enterKeyDown: 'enterKeyDown',
  tabKeyDown: 'tabKeyDown',
  shiftTabKeyDown: 'shiftTabKeyDown',
}

// https://github.com/mui/mui-x/pull/3738#discussion_r798504277
export { GridCellEditStartReasons, GridCellEditStopReasons };
