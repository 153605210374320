import * as React from 'react';
import { useThemeProps } from '@mui/material/styles';
import {
  GRID_DEFAULT_LOCALE_TEXT,
  DATA_GRID_PROPS_DEFAULT_VALUES,
} from '../../x-data-grid';
import {
  computeSlots,
  useProps,
  ROW_SELECTION_PROPAGATION_DEFAULT,
} from '../../x-data-grid/internals';
import { DATA_GRID_PRO_DEFAULT_SLOTS_COMPONENTS } from '../constants/dataGridProDefaultSlotsComponents';


const getDataGridProForcedProps = (themedProps) => ({
  signature: 'DataGridPro',
  ...(themedProps.unstable_dataSource
    ? {
      filterMode: 'server',
      sortingMode: 'server',
      paginationMode: 'server',
    }
    : {}),
});

/**
 * The default values of `DataGridProPropsWithDefaultValue` to inject in the props of DataGridPro.
 */
export const DATA_GRID_PRO_PROPS_DEFAULT_VALUES = {
  ...DATA_GRID_PROPS_DEFAULT_VALUES,
  autosizeOnMount: false,
  defaultGroupingExpansionDepth: 0,
  disableAutosize: false,
  disableChildrenFiltering: false,
  disableChildrenSorting: false,
  disableColumnPinning: false,
  getDetailPanelHeight: () => 500,
  headerFilters: false,
  keepColumnPositionIfDraggedOutside: false,
  rowSelectionPropagation: ROW_SELECTION_PROPAGATION_DEFAULT,
  rowReordering: false,
  rowsLoadingMode: 'client',
  scrollEndThreshold: 80,
  treeData: false,
  unstable_listView: false,
};

const defaultSlots = DATA_GRID_PRO_DEFAULT_SLOTS_COMPONENTS;

export const useDataGridProProps = (inProps) => {
  const themedProps = useProps(
    useThemeProps({
      props: inProps,
      name: 'MuiDataGrid',
    }),
  );

  const localeText = React.useMemo(
    () => ({ ...GRID_DEFAULT_LOCALE_TEXT, ...themedProps.localeText }),
    [themedProps.localeText],
  );

  const slots = React.useMemo(
    () =>
      computeSlots({
        defaultSlots,
        slots: themedProps.slots,
      }),
    [themedProps.slots],
  );

  return React.useMemo(
    () => ({
      ...DATA_GRID_PRO_PROPS_DEFAULT_VALUES,
      ...themedProps,
      localeText,
      slots,
      ...getDataGridProForcedProps(themedProps),
    }),
    [themedProps, localeText, slots],
  );
};
