import { createSelector, createSelectorMemoized } from '../../../utils/createSelector';
import { gridRowTreeSelector, gridRowsLookupSelector } from '../rows/gridRowsSelector';
import { GRID_ID_AUTOGENERATED, isAutogeneratedRowNode } from '../rows/gridRowsUtils';

/**
 * @category Sorting
 * @ignore - do not document.
 */
const gridSortingStateSelector = (state) => state.sorting;

/**
 * Get the id of the rows after the sorting process.
 * @category Sorting
 */
export const gridSortedRowIdsSelector = createSelector(
  gridSortingStateSelector,
  (sortingState) => sortingState.sortedRows,
);

/**
 * Get the id and the model of the rows after the sorting process.
 * @category Sorting
 */
export const gridSortedRowEntriesSelector = createSelectorMemoized(
  gridSortedRowIdsSelector,
  gridRowsLookupSelector,
  gridRowTreeSelector,
  (sortedIds, idRowsLookup, rowTree) =>
    sortedIds.reduce((acc, id) => {
      const model = idRowsLookup[id];
      if (model) {
        acc.push({ id, model });
      }
      const rowNode = rowTree[id];
      if (rowNode && isAutogeneratedRowNode(rowNode)) {
        acc.push({ id, model: { [GRID_ID_AUTOGENERATED]: id } });
      }
      return acc;
    }, []),
);

/**
 * Get the current sorting model.
 * @category Sorting
 */
export const gridSortModelSelector = createSelector(
  gridSortingStateSelector,
  (sorting) => sorting.sortModel,
);


/**
 * @category Sorting
 * @ignore - do not document.
 */
export const gridSortColumnLookupSelector = createSelectorMemoized(
  gridSortModelSelector,
  (sortModel) => {
    const result = sortModel.reduce((res, sortItem, index) => {
      res[sortItem.field] = {
        sortDirection: sortItem.sort,
        sortIndex: sortModel.length > 1 ? index + 1 : undefined,
      };
      return res;
    }, {});
    return result;
  },
);
