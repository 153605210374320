import utils from '../../utils';

export default {
    get: utils.actions(async (code, { extra, getState }) => {
        try {
            let api = extra.api;
            let sets = getState().sets;

            let result = (await api.method.get("api/v1/public/set/" + code)).cards;

            let price = 0;
            let priceFoil = 0;
            for (let card of result) {
                if (card?.prices?.eur != undefined) {
                    price += parseFloat(card?.prices?.eur.toString());
                }
                if (card?.prices?.eur_foil != undefined) {
                    priceFoil += parseFloat(card?.prices?.eur_foil.toString());
                }
            }

            let set = sets.find((e) => e.code == code);

            set.prices = {
                eur: price,
                eur_foil: priceFoil
            };

            set.cards = result;

            return {
                set: set
            }
        } catch (err) {
            throw { message: err.message };
        }
    })
}